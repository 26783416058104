#mainfooter{
    background-color:#2d3045;
    color:white;
    width: 100%;
    height: fit-content;
    position:relative;
    bottom: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

@media only screen and (max-width: 480px) {
    #footer-things{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        text-align: center;
    }

    #links, a{
        text-decoration: none;
        text-align: center;
        color: whitesmoke;
    }

    li{
        text-align: center;
    }
    
    #contacts{
        flex-direction: column;
        flex-wrap: wrap;
        display: flex;
        padding-bottom: 15px;
        align-items: center;
    }
}



#links, a{
    text-decoration: none;
    text-align: left;
    color: whitesmoke;
}

#links:hover, a:hover{
    text-decoration: none;
    text-align: left;
    color: rgb(228, 220, 220);
    background-color: rgb(19, 7, 7);
    border: #2d3045;
    border-radius: 15px;
    padding: 5px;
    margin: 2px;
}

li{
    text-align: left;
}

#contacts{
    flex-direction: column;
    flex-wrap: wrap;
    display: flex;
    padding-bottom: 15px;
    align-items: flex-start;
}