#mainimage{
    width: 100%;
    height: fit-content;
}

#captions{
    margin-bottom: 3em;
    color: white;
    font-size: xx-large;
    background-image: linear-gradient(180deg, #f7f6f3, #f71606);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 100%
}

#welcome{
    width: 100%;
    height: fit-content;
}

#header{
    width: 100%;
    height: fit-content;
    top: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: white ;
    padding: auto;
}



#btncontact{
    background-color: #ca381e;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 24px;
    margin-top: 10em;
    border-radius: 15px;
    animation: glowing 1300ms infinite;
}

#btncontact:hover{
    -webkit-transform: translateY(-3px);
	transform: translateY(-3px);
    animation: glowing 1300ms infinite;
}

@keyframes glowing {
    0% {
      background-color: #0e9de0;
      box-shadow: 0 0 10px #0896ce;
    }
    50% {
      background-color: #0c8ad3;
      box-shadow: 0 0 20px #088dc2fb;
    }
    100% {
      background-color: #0595a8;
      box-shadow: 0 0 10px #069cf3;
    }
}

#vid{
    object-fit: contain;
    width: 100%;
    height: fit-content;
    
}

span{
    color: rgb(129, 125, 120);
}

#pics{
    width: 500px;
}

.truck{
    height: 400px;
    width: 30px;
}

@keyframes glow {
    from {
      text-shadow: 0 0 3px #fff, 0 0 20px #fff, 0 0 30px #b8a8a8, 0 0 40px #cc8484, 0 0 50px #be9999, 0 0 60px #ce6262, 0 0 70px #e74141;
    }
    to {
      text-shadow: 0 0 10px #fff, 0 0 30px #d41e1e, 0 0 40px #d82222, 0 0 50px #eb1111, 0 0 60px #eb1111, 0 0 70px #eb1111, 0 0 80px #eb1111;
    }
}

@media only screen and (max-width: 480px) {
    #text{   
        background-image: linear-gradient(180deg, #f7f6f3, #f71606);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: 100%
    }

    #captions{
        margin-bottom: 2em;
        color: white;
        font-size: x-small;
        background-image: linear-gradient(180deg, #f7f6f3, #f71606);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: 100%
    }

    #header{
       
        color: rgb(247, 240, 240);
        text-align: center;
        
        padding-left: 2px;
        padding-right: 2px;
    }

    .truck{
        max-width: 100px;
        max-height: 100px;
    }

    #vid{
        object-fit: cover;
        width: 100%;
        height: 550px;
        
    }

    #mycarousel{
       
    }

    #btncontact{
        background-color: #ca381e;
        border: none;
        color: white;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 14px;
        margin-top: 4em;
        border-radius: 15px;
        animation: glowing 1300ms infinite;
    }
}